<template>
  <div>
    <v-card
      id="settings"
      class="pa-0"
      color="transparent"
      flat
      min-width="80"
      :style="styleBtn"
    >
       <v-btn 
        :retain-focus-on-click="true" 
        small 
        fab
        dark
        color="blue-grey" 
        @click="showInfo = !showInfo">
          <v-icon
            v-text="showInfo ? 'mdi-chevron-double-left' : 'mdi-chevron-double-right'" >
          </v-icon>
        </v-btn>
    </v-card>
  </div>
</template>
<script>
export default {
  name:'SizeMarket',
  props:{
    value:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      showInfo: this.value,
    }
  },
  watch: {
    value(val, oldVal) {
      if(val === oldVal) return 

      this.showInfo = val;
    },
    showInfo(val, oldVal) {
      if(val === oldVal) return 

      this.$emit('input', val);
    },
  },
  computed:{
    styleBtn(){
      return {
        position: this.showInfo ? 'absolute' : 'fixed',
        top: this.showInfo ? '30%' : '45%',
        'margin-left': this.showInfo ? '-25px' : '0px',
        'z-index': 200
      }
    }
  }
}
</script>
<style lang="sass" scoped>

</style>